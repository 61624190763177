/* @import url(https://themes.googleusercontent.com/fonts/css?kit=frLqg2SrdmaP12_qQR-kkqTvUcFDPb81riXnk0ZB3RBbV0WvE1cEyAoIq5yYZlSc);

.terms-div1{
    padding-top: 80px ;
    padding-bottom: 80px;
}
       .terms-div1 ol {
            margin: 0;
            padding: 0
        }

        .terms-div1 table td,
        table th {
            padding: 0
        }

       .terms-div1 .c11 {
            color: #000000;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 24pt;
            font-family: "Poppins";
            font-style: normal
        }

      .terms-div1  .c13 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: justify;
            height: 12pt
        }

    .terms-div1    .c9 {
            padding-top: 0pt;
            padding-bottom: 14pt;
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: left
        }

    .terms-div1    .c12 {
            padding-top: 2.2pt;
            padding-bottom: 14pt;
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: justify
        }

    .terms-div1    .c2 {
            padding-top: 0pt;
            padding-bottom: 14pt;
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: justify
        }

        .terms-div1 .c1 {
            text-decoration: none;
            vertical-align: baseline;
            font-size: 12pt;
            font-style: normal
        }

        .terms-div1 .c6 {
            font-family: "Poppins";
            color: #323338;
            font-weight: 700
        }

        .terms-div1 .c5 {
            color: #4353ff;
            font-weight: 400;
            font-family: "Poppins"
        }

        .terms-div1 .c0 {
            font-family: "Poppins";
            color: #323338;
            font-weight: 400
        }

        .terms-div1 .c8 {
            background-color: #ffffff;
            max-width: 468pt;
            padding: 72pt 72pt 72pt 72pt
        }

        .terms-div1 .c4 {
            font-family: "Times New Roman";
            color: #323338;
            font-weight: 400
        }

        .terms-div1 .c7 {
            color: #000000;
            font-weight: 400;
            font-family: "Aptos"
        }

        .terms-div1 .c3 {
            -webkit-text-decoration-skip: none;
            text-decoration: underline;
            text-decoration-skip-ink: none
        }

        .terms-div1 .c10 {
            color: inherit;
            text-decoration: inherit
        }

        .terms-div1 .title {
            padding-top: 0pt;
            color: #000000;
            font-size: 28pt;
            padding-bottom: 4pt;
            font-family: "Play";
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .terms-div1 .subtitle {
            padding-top: 0pt;
            color: #595959;
            font-size: 14pt;
            padding-bottom: 8pt;
            font-family: "Aptos";
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .terms-div1 li {
            color: #000000;
            font-size: 12pt;
            font-family: "Aptos";
            line-height: 22px !important;

        }

        .terms-div1 p {
            margin: 0;
            color: #000000;
            font-size: 12pt;
            font-family: "Aptos";
            line-height: 22px !important;
        }

        .terms-div1 h1 {
            padding-top: 18pt;
            color: #0f4761;
            font-size: 20pt;
            padding-bottom: 4pt;
            font-family: "Play";
            line-height: 1.0;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

      .terms-div1  h2 {
            padding-top: 8pt;
            color: #0f4761;
            font-size: 16pt;
            padding-bottom: 4pt;
            font-family: "Play";
            line-height: 1.0;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

      .terms-div1  h3 {
            padding-top: 8pt;
            color: #0f4761;
            font-size: 14pt;
            padding-bottom: 4pt;
            font-family: "Aptos";
            line-height: 1.0;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

       .terms-div1 h4 {
            padding-top: 4pt;
            color: #0f4761;
            font-size: 12pt;
            padding-bottom: 2pt;
            font-family: "Aptos";
            line-height: 1.0;
            page-break-after: avoid;
            font-style: italic;
            orphans: 2;
            widows: 2;
            text-align: left
        }

     .terms-div1   h5 {
            padding-top: 4pt;
            color: #0f4761;
            font-size: 12pt;
            padding-bottom: 2pt;
            font-family: "Aptos";
            line-height: 1.0;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

      .terms-div1  h6 {
            padding-top: 2pt;
            color: #595959;
            font-size: 12pt;
            padding-bottom: 0pt;
            font-family: "Aptos";
            line-height: 1.0;
            page-break-after: avoid;
            font-style: italic;
            orphans: 2;
            widows: 2;
            text-align: left
        } */



.terms-div1 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.terms-div1 h1{
    color: #042B3F;
font-family: Poppins;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: 58px; /* 104.167% */
text-transform: uppercase;
}

.terms-div1 h2{
    color: #042B3F;
font-family: Poppins;
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: 38px; /* 178.571% */
text-transform: capitalize;
margin-top: 28px;
}

.terms-div1 p{
    color: #545454;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 125% */
margin-bottom: 22px;
}

.terms-div1 p span{
    text-decoration: underline;
}




@media screen and (max-width: 576px) {

.terms-div1 {
  padding-top: 65px;
  padding-bottom: 65px;
}

.terms-div1 h1{
font-size: 32px;
line-height: 46px; /* 104.167% */
}

.terms-div1 h2{
    font-size: 22px;
line-height: 34px; /* 178.571% */
margin-top: 28px;
margin-bottom: 14px;
}

.terms-div1 p{
font-size: 14px;
line-height: 22px; /* 125% */
margin-bottom: 20px;
}

}
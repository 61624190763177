 @import url(https://themes.googleusercontent.com/fonts/css?kit=frLqg2SrdmaP12_qQR-kkqTvUcFDPb81riXnk0ZB3RBbV0WvE1cEyAoIq5yYZlSc);

 .cookies-div1{
    padding-top: 80px;
    padding-bottom: 80px;
 }

        .lst-kix_list_2-6>li:before {
            content: "\0025aa   "
        }

        .cookies-div1 .lst-kix_list_2-7>li:before {
            content: "\0025aa   "
        }

        .cookies-div1 ul.lst-kix_list_1-0 {
            list-style-type: none
        }

        .cookies-div1 .lst-kix_list_2-4>li:before {
            content: "\0025aa   "
        }

        .cookies-div1 .lst-kix_list_2-5>li:before {
            content: "\0025aa   "
        }

        .cookies-div1 .lst-kix_list_2-8>li:before {
            content: "\0025aa   "
        }

        .cookies-div1 ul.lst-kix_list_2-8 {
            list-style-type: none
        }

        .cookies-div1 ul.lst-kix_list_1-3 {
            list-style-type: none
        }

        .cookies-div1 ul.lst-kix_list_2-2 {
            list-style-type: none
        }

        .cookies-div1 .lst-kix_list_1-0>li:before {
            content: "\0025cf   "
        }

        .cookies-div1 ul.lst-kix_list_1-4 {
            list-style-type: none
        }

        .cookies-div1 ul.lst-kix_list_2-3 {
            list-style-type: none
        }

        .cookies-div1 ul.lst-kix_list_1-1 {
            list-style-type: none
        }

        .cookies-div1 ul.lst-kix_list_2-0 {
            list-style-type: none
        }

        .cookies-div1 ul.lst-kix_list_1-2 {
            list-style-type: none
        }

        .cookies-div1 ul.lst-kix_list_2-1 {
            list-style-type: none
        }

        .cookies-div1 ul.lst-kix_list_1-7 {
            list-style-type: none
        }

        .cookies-div1 ul.lst-kix_list_2-6 {
            list-style-type: none
        }

        .cookies-div1 .lst-kix_list_1-1>li:before {
            content: "o  "
        }

        .cookies-div1 .lst-kix_list_1-2>li:before {
            content: "\0025aa   "
        }

        .cookies-div1 ul.lst-kix_list_1-8 {
            list-style-type: none
        }

        .cookies-div1 ul.lst-kix_list_2-7 {
            list-style-type: none
        }

        .cookies-div1 ul.lst-kix_list_1-5 {
            list-style-type: none
        }

        .cookies-div1 ul.lst-kix_list_2-4 {
            list-style-type: none
        }

        .cookies-div1 ul.lst-kix_list_1-6 {
            list-style-type: none
        }

        .cookies-div1 ul.lst-kix_list_2-5 {
            list-style-type: none
        }

        .cookies-div1 .lst-kix_list_1-3>li:before {
            content: "\0025aa   "
        }

        .cookies-div1 .lst-kix_list_1-4>li:before {
            content: "\0025aa   "
        }

        .cookies-div1 .lst-kix_list_1-7>li:before {
            content: "\0025aa   "
        }

        .cookies-div1 .lst-kix_list_1-5>li:before {
            content: "\0025aa   "
        }

        .cookies-div1 .lst-kix_list_1-6>li:before {
            content: "\0025aa   "
        }

        .cookies-div1 li.li-bullet-0:before {
            margin-left: -18pt;
            white-space: nowrap;
            display: inline-block;
            min-width: 18pt
        }

        .cookies-div1 .lst-kix_list_2-0>li:before {
            content: "\0025cf   "
        }

        .cookies-div1 .lst-kix_list_2-1>li:before {
            content: "o  "
        }

        .cookies-div1 .lst-kix_list_1-8>li:before {
            content: "\0025aa   "
        }

        .cookies-div1 .lst-kix_list_2-2>li:before {
            content: "\0025aa   "
        }

        .cookies-div1 .lst-kix_list_2-3>li:before {
            content: "\0025aa   "
        }

        .cookies-div1 ol {
            margin: 0;
            padding: 0
        }

        .cookies-div1 table td,
        table th {
            padding: 0
        }

        .cookies-div1 .c6 {
            margin-left: 36pt;
            padding-top: 0pt;
            padding-left: 0pt;
            padding-bottom: 0pt;
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: justify
        }

        .cookies-div1 .c10 {
            margin-left: 36pt;
            padding-top: 14pt;
            padding-left: 0pt;
            padding-bottom: 0pt;
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: justify
        }

        .cookies-div1 .c12 {
            color: #000000;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 24pt;
            font-family: "Poppins";
            font-style: normal
        }

        .cookies-div1 .c9 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: justify;
            height: 12pt
        }

        .cookies-div1 .c5 {
            -webkit-text-decoration-skip: none;
            color: #4353ff;
            font-weight: 400;
            text-decoration: none;
            text-decoration-skip-ink: none;
            font-family: "Poppins"
        }

        .cookies-div1 .c15 {
            padding-top: 2.2pt;
            padding-bottom: 14pt;
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: justify
        }

        .cookies-div1 .c1 {
            font-weight: 700
        }

     

        .cookies-div1 .c14 {
            background-color: #ffffff;
            max-width: 468pt;
            padding: 72pt 72pt 72pt 72pt
        }

        .cookies-div1 .c11 {
            font-family: "Poppins";
            color: #4353ff;
            font-weight: 400
        }

        .cookies-div1 .c16 {
            color: #000000;
            font-weight: 400;
            font-family: "Aptos"
        }

        .cookies-div1 .c4 {
            padding: 0;
            margin: 0
        }

        .cookies-div1 .c2 {
            color: inherit;
            text-decoration: inherit
        }

        .cookies-div1 .c13 {
            margin-left: 36pt;
            padding-left: 0pt
        }

        .cookies-div1 li {
            color: #000000;
            font-size: 12pt;
            font-family: "Aptos";
            line-height: 23px !important;

        }



        

.cookies-div1 h1{
    color: #042B3F;
font-family: Poppins;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: 58px; /* 104.167% */
text-transform: uppercase;
}

.cookies-div1 h2{
    color: #042B3F;
font-family: Poppins;
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: 38px; /* 178.571% */
text-transform: capitalize;
margin-top: 28px;
margin-bottom: 14px;
}

.cookies-div1 p{
    color: #545454;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 125% */
margin-bottom: 22px;
}

/* .cookies-div1 p span{
    text-decoration: underline;
} */



@media screen and (max-width: 576px) {
      
    .cookies-div1 {
  padding-top: 65px;
  padding-bottom: 65px;
  overflow: hidden;
}

.cookies-div1 h1{
font-size: 32px;
line-height: 46px; /* 104.167% */
text-transform: uppercase;
}

.cookies-div1 h2{
font-size: 22px;
line-height: 34px; /* 178.571% */
margin-top: 28px;
margin-bottom: 14px;
}

.cookies-div1 p{
font-size: 14px;
line-height: 22px; /* 125% */
margin-bottom: 20px;

 }

 .cookies-div1 ul.lst-kix_list_2-0 .c6 {
    font-size: 14px !important;
    margin-left: 28px;
}

 .cookies-div1 ul.lst-kix_list_2-0 .c10 {
    font-size: 14px !important;
    margin-left: 28px;

}

.cookies-div1 ul.lst-kix_list_2-0 .c3 {
    font-size: 14px !important;
    margin-left: 28px;

}
}
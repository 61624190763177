@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

a {
  text-decoration: none !important;
}

.navbar-div1 {
  position: relative;
  width: 100% !important;
  z-index: 9;
  background: #042B3F;
  padding: 27px 110px;
}

.transparent-navbar-div1 {
  position: absolute;
  width: 100% !important;
  z-index: 9;
  background: transparent;
  padding: 30px 110px;
}

@media screen and (max-width: 1450px) {
.navbar-div1 {
  padding: 20px 65px;
}
.transparent-navbar-div1 {
  padding: 20px 65px;
}
}



.navbar-ul-div1 {
  display: flex !important;
  justify-content: center;
    margin-right: 0px !important;
}

.navbar-logo1{
  width: 200px;
}

@media screen and (max-width: 2000px) {
  .navbar-logo1{
  width: 170px;
}
}

@media screen and (max-width: 1800px) {
  .navbar-logo1{
  width: 150px;
}
}

@media screen and (max-width: 1600px) {
  .navbar-logo1{
  width: 140px;
}
}

@media screen and (max-width: 1272px) {
  .navbar-logo1{
  width: 130px;
}
}

.navbar-ul1 {
  column-gap: 22px;
  display: flex;
  align-items: center;
}

.navbar-ul1 .nav-item {
  display: flex;
  align-items: center;
}

.navbar-ul1 .nav-item .nav-link {
color: #FFF;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.navbar-ul1 .nav-item .nav-link:hover {
  color: #FFBC49 !important;
}


@media screen and (max-width: 1370px) {
.navbar-div1 {
  padding: 20px 50px;
}
.transparent-navbar-div1 {
  padding: 20px 50px;
}
.navbar-ul1 {
  column-gap: 14px;
  display: flex;
  align-items: center;
}
}


@media screen and (max-width: 1272px) {
  .navbar-div1 {
  padding: 20px 20px;
}
.transparent-navbar-div1 {
  padding: 20px 20px;
}
.navbar-ul1 .nav-item .nav-link {
font-size: 14px;
}
}


.navbar-contact-btn1 {
  color: #01070c;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0px !important;
  width: 156px;
  height: 50px;
  flex-shrink: 0;
  background: linear-gradient(
    95deg,
    #f7b53c 0.98%,
    #f7b53c 42.72%,
    #ffcd73 90.99%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}





.mobile-navbar-div1 {
  background: #042B3F !important;
  min-height: 80px;
  width: 100%;
  z-index: 9999;
  padding-left: 30px;
  padding-right: 30px;
}

.transparent-mobile-navbar-div1 {
  background: transparent !important;
  min-height: 80px;
  width: 100%;
  z-index: 9999;
  position: absolute;
  padding-left: 30px;
  padding-right: 30px;
}

@media screen and (max-width: 991px) {
  /* .navbar-div1 {
        display: none;
    } */

  .mobile-navbar-div1 {
    display: block;
    width: 100%;
    top: 0px;
    left: 50%;
    z-index: 9999;
    transform: translatex(-50%);
  }
  .transparent-mobile-navbar-div1 {
    display: block;
    width: 100%;
    top: 0px;
    left: 50%;
    z-index: 9999;
    transform: translatex(-50%);
  }
}

.mobile-nav-toogler {
  border: none;
  /* background: linear-gradient(95deg, #F7B53C 0.98%, #F7B53C 42.72%, #FFCD73 90.99%); */
  padding: 0px;
}

.mobile-nav-item .nav-link {
  color: #101010;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 191.812% */
letter-spacing: -0.18px;
  margin-left: 0px !important;
}


.mobile-nav-item.home1 .mobilenav-lines-svg {
  min-width: 100%;
  height: 0.5px;
  background: #000 !important;
  margin-top: 8px;
  margin-bottom: 10px;
}

.mobile-nav-item .nav-link:hover {
  color: #101010 !important;
}

.mobile-btn-lin1{
background: white;
height: 2px;
width: 26px;
}

.mobile-btn-lin2{
background: white;
height: 2px;
margin-top: 5px;
width: 26px;
}

.mobile-btn-lin3{
background: white;
height: 2px;
}

.mobile-navbar-div2 {
  /* background: #EAEFFF; */
  background-color: #fff;
  padding-top: 15px;
  padding-bottom: 30px;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  padding-right: 30px;
  z-index: 3000 !important;
  padding-left: 30px;
  display: none !important;
  animation: fade 0.5s ease-in-out;
}

.mobile-navbar-div2.show {
  background-color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 90px;
  transition: none;
  z-index: 9999;
  display: block !important;
  animation: fade 0.5s ease-in-out;
  border-radius: 7px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mobile-nav-item.active .nav-link {
  color: black !important;
}

@media screen and (max-width: 576px) {
  .nav-text1,
  .dark-nav-text1 {
    font-size: 16px;
  }

  .navbar-contact-btn1 {
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 110px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 23px;
    background: linear-gradient(90deg, #40e570 0.13%, #84ffa8 99.68%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    margin-top: 5px;
  }
}

.mobile-call-btn1 {
  background: #FFBC49;
  padding: 12px;
}



.mobile-qoute-btn122 {
  border-radius: 6px;
background: #FFC155;
box-shadow: 0px 0px 0px 0px #FFF inset, 0px 0px 0px 1px #BD801B inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  padding-top: 14px;
  padding-bottom: 12px;
  margin-top: 10px;
}

.mobile-qoute-btn111 {
  width: 100%;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 22px;
  line-height: 28px; /* 180.074% */
  padding: 14px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 0px;
  border-radius: 6px;
background: #FFC155;
box-shadow: 0px 0px 0px 0px #FFF inset, 0px 0px 0px 1px #BD801B inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

}


.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #fff;
}



.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #fff !important;
}

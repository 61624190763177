.event-banner-div1{
padding-bottom: 85px;
padding-top: 85px;
    background: #FFFAF2;
}

.event-banner-text-div1 h1{
color: #042B3F;
text-align: center;
font-family: Poppins;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: 54px; /* 104.167% */
text-transform: uppercase;
}

.event-banner-text-div1 p{
color: #545454;
text-align: center;
font-family: Poppins;
font-size: 38px;
font-style: normal;
font-weight: 500;
line-height: 44px; /* 100% */
}

.event-img-div1{
    margin-top: 48px;
    display: flex;
    justify-content: center;
}

.event-btn1 a div{
color: #000;
    font-family: Inter;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 280px;
    height: 64px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #FFC155;
    box-shadow: 0px 0px 0px 0px #FFF inset, 0px 0px 0px 1px #BD801B inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 28px;
}


@media screen and (max-width: 576px) {
.event-banner-div1{
    padding-bottom: 65px;
padding-top: 65px;
}

.event-banner-text-div1 h1{
font-size: 36px;
line-height: 46px; /* 104.167% */
text-transform: uppercase;
}

.event-banner-text-div1 p{
font-size: 28px;
line-height: 36px; /* 136.364% */
}


.event-img-div1{
    margin-top: 30px;
    display: flex;
    justify-content: center;
}



.event-btn1 a div{
    font-size: 20px;
    width: 270px;
    height: 62px;
    margin-top: 20px;
}

}
.dev-banner-div1{
min-height: 88.2vh;
overflow: hidden;
/* padding-top: 88px; */
}

.dev-banner-col-div1{
background: #FFFAF2;
padding: 60px 90px;
display: grid;
align-items: center;
min-height: 88.1vh;
}

.dev-banner-text-div1 h1{
color: #042B3F;
font-family: Poppins;
font-size: 46px;
font-style: normal;
font-weight: 700;
line-height: 50px; /* 104.167% */
text-transform: uppercase;
}

.dev-banner-text-div1 p{
color: #042B3F;
font-family: Inter;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 144.444% */
margin-top: 24px;
}


.dev-banner-text-div1 div a div{
color: #000;
font-family: Inter;
font-size: 21px;
font-style: normal;
font-weight: 700;
line-height: normal;
width: 359px;
height: 64px;
flex-shrink: 0;border-radius: 6px;
background: #FFC155;
box-shadow: 0px 0px 0px 0px #FFF inset, 0px 0px 0px 1px #BD801B inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
display: flex;
align-items: center;
justify-content: center;
margin-top: 35px;
}

.dev-banner-img-div1{
    background: url(../Image/Manusing\ \(1\).jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 21%;
}

@media screen and (max-width: 1420px) {
.dev-banner-img-div1{
    background-position-x: 28%;
}
}



@media screen and (max-width: 1380px) {
    
.dev-banner-col-div1{
padding: 60px 80px;
}

.dev-banner-text-div1 h1{
font-size: 46px;
line-height: 50px; /* 104.167% */
}
.dev-banner-img-div1{
    background-position-x: 45%;
}
}

@media screen and (max-width: 1290px) {
    
.dev-banner-col-div1{
padding: 60px 65px;
}

.dev-banner-text-div1 h1{
font-size: 43px;
line-height: 50px; /* 104.167% */
}
}




















/* ################ Section ##### 2 #####################} */

.dev-sec2-div1{
    padding: 15px 0px;
}

.dev-img-main-div1{
    display: flex;
    flex-wrap: wrap;
    row-gap: 15px;
    overflow: hidden;
}

.dev-img-1{
    width: 38%;
    padding-right: 7.5px;
}

.dev-img-1 img{
    width: 100%;
    height: 100%;
}

.dev-img-2{
    width: 38%;
    padding-right: 7.5px;
    padding-left: 7.5px;
}

.dev-img-2 img{
    width: 100%; 
    height: 100%;
}

.dev-img-3{
    width: 24%;
    padding-left: 7.5px;
}

.dev-img-3 img{
    width: 100%; 
    height: 100%;
}





.dev-img-4{
    width: 18%;
    padding-right: 7.5px;
}

.dev-img-4 img{
    width: 100%;
    height: 100%;
}

.dev-img-5{
    width: 50%;
    padding-right: 7.5px;
    padding-left: 7.5px;
}

.dev-img-5 img{
    width: 100%; 
    height: 100%;
}

.dev-img-6{
    width: 32%;
    padding-left: 7.5px;
}

.dev-img-6 img{
    width: 100%; 
    height: 100%;
}





























@media screen and (max-width: 576px) {
    .dev-banner-div1{
min-height: 88.2vh;
overflow: hidden;
/* padding-top: 88px; */
}

.dev-banner-col-div1{
background: #FFFAF2;
padding: 60px 30px;
display: grid;
align-items: center;
min-height: 88.1vh;
}

.dev-banner-text-div1 h1{
font-size: 30px;
line-height: 36px; /* 104.167% */
}

.dev-banner-text-div1 p{
font-size: 15px;
line-height: 22px; /* 144.444% */
margin-top: 10px;
}

.dev-banner-text-div1 div img{
margin-top: 10px;
margin-bottom: 14px;
border-radius: 10px;
}


.dev-banner-text-div1 div a div{
color: #000;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
width: 100%;
height: 60px;
flex-shrink: 0;
background: #FFC45F;
display: flex;
align-items: center;
justify-content: center;
margin-top: 35px;
}


.dev-banner-text-div1 div.d-block a div{
color: #000;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
width: 100%;
height: 60px;
flex-shrink: 0;
background: #FFE3B1;
display: flex;
align-items: center;
justify-content: center;
margin-top: 15px;
}




















/* ################ Section ##### 2 #####################} */

.dev-sec2-div1{
    padding: 25px 25px;
}

.dev-img-main-div1{
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    overflow: hidden;
}

.dev-img-1{
    width: 25%;
    padding-right: 5px;
}

.dev-img-1 img{
    width: 100%;
    height: 100%;
}

.dev-img-2{
    width: 75%;
    padding-left: 5px;
    padding-right: 0px;
}

.dev-img-2 img{
    width: 100%; 
    height: 100%;
}

.dev-img-3{
    width: 50%;
    padding-right: 5px;
    padding-left: 0px;
}

.dev-img-3 img{
    width: 100%; 
    height: 100%;
}


.dev-img-4{
    width: 50%;
    padding-left: 5px;
    padding-right: 0px;
}

.dev-img-4 img{
    width: 100%;
    height: 100%;
}

.dev-img-5{
    width: 75%;
    padding-right: 5px;
    padding-left: 0px;
}

.dev-img-5 img{
    width: 100%; 
    height: 100%;
}

.dev-img-6{
    width: 25%;
    padding-left: 5px;
    padding-right: 0px;
}

.dev-img-6 img{
    width: 100%; 
    height: 100%;
}






  
}


.footer-div1{
background: #042B3F;
padding-top: 80px;
padding-bottom: 20px;
}

.footer-div1 .container{
padding-left: 100px;
padding-right: 100px;
}

.footer-logo-11{
margin-bottom: 40px;
display: flex;
justify-content: center;
}

.footer-amplify-text{
    color: rgba(255, 255, 255, 0.75);
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 94.545% */
text-align: center;
}



.footer-li-text-div1{
    margin-top: 25px;
display: flex;
align-items: center;
column-gap: 25px;
flex-wrap:wrap;
}

.footer-li-text-div1 a p{
  color:  #FFF;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 140% */
text-transform: capitalize;
}


.footer-winning-text-div{
margin-top: 52px;
}

.footer-winning-text-div h1{
color:  #FFF;
font-family: Inter;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 68.698px; /* 171.745% */
}

.footer-winning-text-div:hover svg{
/* transform: translate(10px , -10px); */
transform: scale(1.1);
transition: all 0.4s ease-in-out;
}


.footer-winning-text-div:hover svg path{
fill: #FFF !important;
}

.footer-line-11{
  background: #FFBC49;
  min-width: 100%;
height: 1.5px;
margin-top: 16px;
}

.footer-winning-text-div:hover+.footer-line-11{
 background: white !important;
transition: all 0.4s ease-in-out;
}



.footer-line-22{
background: rgba(255, 255, 255, 0.20);
min-width: 100%;
height: 1px;
margin-top: 50px;
}

.footer-copyright-text-div1{
  display: flex;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 15px;
}

.footer-copyright-text-div1 p{
  color: rgba(255, 255, 255, 0.50);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 22.4px; /* 160% */
margin-bottom: 0px !important;
}

.footer-copyright-text-div22{
  display: flex;
  align-items: center;
  column-gap: 20px;
}


.social-footer-icons-div1{
    display: flex;
    align-items: center;
    column-gap: 20px;
    justify-content: center;
    margin-bottom: 28px;
}

.social-footer-icons-div1 div img{
    width: 40px;
}























@media only screen and (max-width: 576px) {


.footer-div1{
padding-top: 60px;
padding-bottom: 35px;
}

.footer-div1 .container{
padding-left: 20px;
padding-right: 20px;
}

.footer-text1{
font-size: 42px;
line-height: 48px; /* 106.498% */
margin-bottom: 28px;
}


.footer-li-text-div1{
display: flex;
align-items: center;
column-gap: 25px;
margin-bottom: 20px;
}

.footer-li-text-div1 a p{
font-size: 15px;
line-height: 20px; /* 140% */
}


.footer-winning-text-div{
margin-top: 45px;
align-items: center;
}

.footer-winning-text-div h1{
font-size: 21px;
line-height: 30px; /* 171.745% */
}

.footer-winning-text-div svg{
    width: 40px;
    height: 40px;
}


.footer-line-22{
margin-top: 35px;
margin-bottom: 20px;
}

.footer-copyright-text-div1{
  display: block;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 15px;
  text-align: center;
}

.footer-copyright-text-div1 p{
font-size: 13px;
line-height: 17px; /* 160% */
margin-bottom: 0px !important;
}

.footer-copyright-text-div22{
  display: flex;
  align-items: center;
  column-gap: 30px;
  justify-content: center;
  margin-top: 30px;
  flex-wrap: wrap;
  row-gap: 16px;
}



.social-footer-icons-div1 div{
  width: 40px;
  height: 40px;
  display: flex ;
  align-items: center;
  justify-content: center;
  border: 1px #797979 solid;
  padding: 10px;
  margin-bottom: 16px;
}

}
.about-banner-div1{
padding-top: 94px;
}
/* 
@media screen and (max-width: 1280px) {
.about-banner-div1{
min-height: 70vh;
}
} */

.about-banner-text-div1 h1{
color: #042B3F;
text-align: center;
font-family: Poppins;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: 50px; /* 104.167% */
text-transform: uppercase;
}

.about-banner-text-div1 p{
color: #000;
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: 30px;
text-align: center;
margin-top: 13px;
}

.about-banner-text-div1 p span:first-child{
font-weight: 700;
}

.about-banner-text-div1 p span:first-child span{
color: #F1A92C;
}
























.about-sec2-div1{
    background: url(../Image/Rectangle\ 4320.svg);
    /* background-color: #002031; */
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 100px;
    margin-top: 195px;
    /* overflow-x: hidden; */
}

.about-sec2-div1 .container {
    z-index: 999;
    position: relative;
}

.about-sec2-svg{
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.about-sec2-img1 img{
    margin-top: -120px;
    position: relative;
    z-index: 99;
}




.about-sec2-text-div1{
    padding-left: 125px;
    padding-right: 125px;
    margin-top: 45px;
}



.about-sec2-text-div1 p{
color: #FFF;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 26px; /* 144.444% */
margin-top: 34px;
margin-bottom: 0px !important;
}

.about-sec2-text-div1 div a div{
    border-radius: 8px;
background: #FFC155;
box-shadow: 0px 0px 0px 0px #FFF inset, 0px 0px 0px 1px #BD801B inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

width: 100%;
padding: 20px 0px;
text-align: center;
color: #000;
font-family: Inter;
font-size: 21px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-top: 30px;
margin-bottom: 0px !important;
}






















.about-sec3-div1{
padding: 20px 0px;
}

.about-sec3-div2{
    display: flex;
}

.about-sec3-img-div1{
width: 25%;
padding-right: 10px;
}

.about-sec3-img-div1 img{
width: 100%;
height: 100%;
}

.about-sec3-img-div2{
width: 50%;
padding-left: 10px;
padding-right: 10px;
}

.about-sec3-img-div2 img{
width: 100%;
height: 100%;
}

.about-sec3-img-div3{
width: 25%;
padding-left: 10px;

}

.about-sec3-img-div3 img{
width: 100%;
height: 100%;
}
























@media screen and (max-width: 576px) {
  .about-banner-div1{
padding-top: 60px;
padding-bottom: 0px;
}


.about-banner-text-div1 h1{
text-align: left;
font-size: 30px;
line-height: 38px; /* 104.167% */
}

.about-banner-text-div1 p{
font-size: 16px;
line-height: 24px;
text-align: left;
margin-top: 10px;
}








.about-sec2-div1{
    padding-bottom: 70px;
    /* padding-top: 100px; */
    margin-top: 88px;
}

.about-sec2-img1 img{
    margin-top: -35px;
    position: relative;
    z-index: 99;
}

.about-sec2-text-div1{
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 40px;
}

.about-sec2-text-div1 p{
font-size: 16px;
line-height: 26px; /* 144.444% */
margin-top: 35px;
margin-bottom: 0px !important;
}

.about-sec2-text-div1 div a div{
padding: 18px 0px;
font-size: 18px;
margin-top: 35px;
margin-bottom: 0px !important;
}


.about-sec2-text-div1 div.d-block a div{
background: #FFE3B1;
width: 100%;
padding: 18px 0px;
text-align: center;
color: #000;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-top: 15px;
margin-bottom: 0px !important;
}














.about-sec3-div1{
padding: 20px 20px;
}

.about-sec3-div2{
    display: flex;
    row-gap: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.about-sec3-img-div1{
width: 30%;
padding-right: 5px;
}

.about-sec3-img-div1 img{
width: 100%;
height: 100%;
}

.about-sec3-img-div2{
width: 70%;
padding-left: 5px;
padding-right: 0px;
}

.about-sec3-img-div2 img{
width: 100%;
height: 100%;
}

.about-sec3-img-div3{
width: 70%;
padding-left: 0px;
padding-right: 5px;
}

.about-sec3-img-div3 img{
width: 100%;
height: 100%;
}


.about-sec3-img-div4{
width: 30%;
padding-left: 5px;
}

.about-sec3-img-div4 img{
width: 100%;
height: 100%;
}


}




.home-banner-div1{
    /* min-height: 100vh; */
    padding: 0px !important;
    text-align: center;
    
}
/* background: lin
ear-gradient(180deg, #032E42 0%, rgba(11, 63, 88, 0.40) 92.7%); */
.home-banner-div2{
    /* min-height: 100vh; */
    background-image: url(../Image/webp/vecteezy_cranes-at-construction-site-beneath-clear-sky-silhouette_27447389\ \(1\).webp);
    background-position-y: 95% ;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-left: 0;
    padding-right: 0px;
}

.home-banner-div2 .home-banner-color-div{
background: linear-gradient(180deg, #032E42 0%, rgba(11, 63, 88, 0.40) 92.7%);
 /* min-height: 100vh; */
    padding-top: 235px;
    padding-bottom: 200px;
    display: flex;
    align-items: center;
}

.home-banner-text-div1 h1{
text-align: center;
font-family: Poppins;
font-size: 62px;
font-style: normal;
font-weight: 700;
line-height: 80px; /* 142.857% */
background: radial-gradient(50% 50% at 50% 50%, #FFDA9B 0%, #FFBC49 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.home-banner-text-div1 h2{
color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 40px;
font-style: normal;
font-weight: 500;
line-height: 50px; /* 250% */
margin-top: 10px;
}

.home-banner-text-div1 div.d-flex.justify-content-center{
    column-gap: 20px;
}

.home-schedule-demo-btn11{
color: #000;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
width: 260px;
height: 60px;
flex-shrink: 0;
border-radius: 6px;
background: #FFC155;
box-shadow: 0px 0px 0px 0px #FFF inset, 0px 0px 0px 1px #BD801B inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
display: flex;
align-items: center;
justify-content: center;
margin-top: 36px;
}


.home-schedule-demo-btn22{
    color: #000;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
width: 260px;
height: 60px;
flex-shrink: 0;
border-radius: 6px;
border: 1px solid #CACACA;
background: #FFF;
display: flex;
align-items: center;
justify-content: center;
margin-top: 36px;
}

.home-banner-img-div1{
    position: relative;
    top: -150px;
}

















.home-core-features-div1{
    padding-top: 100px;
    position: relative;
}

.core-texts-12{
color: #002232;
font-family: Poppins;
text-align: center;
font-size: 52px;
font-style: normal;
font-weight: 700;
line-height: 60px; /* 142.857% */
text-transform: uppercase;
margin-bottom: 70px;
}

.core-texts-12 span{
    color:#FFBC49;
}

.home-core-feature-text-div1{
padding-right: 36px;
}

.home-core-feature-text-div1 h1{
color: #000;
font-family: Poppins;
font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: 44px; /* 110% */
margin-bottom: 20px;
}

.home-core-feature-text-div1 p{
color: #343434;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 300;
line-height: 25px; /* 115% */
}


.home-core-feature-text-div22{
padding-left: 36px;
}

.home-core-feature-text-div22 h1{
color: #000;
font-family: Poppins;
font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: 44px; /* 110% */
margin-bottom: 20px;
}


.home-core-feature-text-div22 p{
color: #343434;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 300;
line-height: 25px; /* 115% */
}

.last-home-core-features-div1{
    padding-top: 100px;
    padding-bottom: 100px;
}

.feature-img112233{
    border-radius: 20px;
}



  

 


 





 /* {#################### Section No ### 4 ###############} */

 .home-work-div1{
    padding-top: 70px ;
    padding-bottom: 110px;
    /* background: #FFFAF1; */
 }

 .home-work-text-div1 h1{
color: #002232;
text-align: center;
font-family: Poppins;
font-size: 52px;
font-style: normal;
font-weight: 700;
line-height: 50px; /* 86.957% */
text-transform: uppercase;
}

 .home-work-text-div1 h1 span{
    color: #FFBC49;

}

.home-work-text-div1 p{
color: #565656;
text-align: center;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 111.111% */
margin-top: 16px;
}

.home-work-row-div1{
    margin-top: 60px;
}

.home-work-card-div1{
padding: 55px 28px;
border-radius: var(--4, 4px);
border-bottom: 7px solid #FFBC49;
background: linear-gradient(180deg, #042B3F 0%, #042B3F 100%);
min-height: 449px;
}

.home-work-card-div1 div{
height: 70px;
display: flex;
align-items: center;
justify-content: center;
}

.home-work-card-div1 h3{
    color: #FFBC49;
text-align: center;
font-family: Inter;
font-size: 23px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top: 16px;

}

.home-work-card-div1 p{
color: #B6B6B6;
text-align: center;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 133.333% */
margin-top: 17px;
margin-bottom: 4px !important;
}


















@media screen and (max-width: 576px) {


    .home-banner-div1{
    min-height: 20vh;
    padding: 0px !important;
    text-align: center;
}

.home-banner-div2{
    min-height: 20vh;
}

.home-banner-div2 .home-banner-color-div{
 min-height: 20vh;
    padding-top: 130px;
    padding-bottom: 70px;
    display: flex;
    align-items: start;
}

.home-banner-text-div1 h1{
font-size: 32px;
line-height: 40px; /* 142.857% */
}

.home-banner-text-div1 h2{
font-size: 22px;
line-height: 28px; /* 250% */
margin-top: 0px;
}

.home-watch-demo-text{
color: #000;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
min-width: 100%;
height: 60px;
flex-shrink: 0;
background: #FFC45F;
display: flex;
align-items: center;
justify-content: center;
margin-top: 32px;
}



.home-banner-text-div1 div.d-flex.justify-content-center {
    column-gap: 20px;
    flex-wrap: wrap;
}


.home-schedule-demo-btn11{
      font-size: 20px;
    width: 320px;
    height: 60px;
    margin-top: 26px;
}
.home-schedule-demo-btn22{
      font-size: 20px;
    width: 320px;
    height: 60px;
    margin-top: 15px;
}


.home-sign-up-text{
    color: #000;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
min-width: 100%;
height: 60px;
flex-shrink: 0;
background: #FFE3B1;
display: flex;
align-items: center;
justify-content: center;
margin-top: 15px;
}

.home-banner-img-div1{
    position: relative;
    top: -60px;
    padding-left: 20px;
    padding-right: 20px;
}























.home-core-features-div1{
    padding-top: 45px;
    position: relative;
}

.core-texts-12{
text-align: center;
font-size: 36px;
line-height: 44px; /* 142.857% */
margin-bottom: 40px;
}

.home-core-feature-text-div1{
padding-right: 0px;
}

.home-core-feature-text-div1 h1{
font-size: 30px;
line-height: 38px; /* 110% */
margin-bottom: 0px;
}

.home-core-feature-text-div1 p{
font-size: 14px;
line-height: 20px; /* 115% */
}

.home-core-feature-text-div1 img{
    margin-top: 22px;
    margin-bottom: 22px;
}


.home-core-feature-text-div22{
padding-left: 0px;
}

.home-core-feature-text-div22 h1{
font-size: 30px;
line-height: 38px; /* 110% */
margin-bottom: 0px;
}


.home-core-feature-text-div22 p{
font-size: 14px;
line-height: 20px; /* 115% */
}

.home-core-feature-text-div22 img{
    margin-top: 22px;
    margin-bottom: 22px;
}

.last-home-core-features-div1{
    padding-top: 45px;
    padding-bottom: 50px;
}

















 /* {#################### Section No ### 4 ###############} */

 .home-work-div1{
    padding-top: 60px ;
    padding-bottom: 65px;
 }

 .home-work-text-div1 h1{
font-size: 36px;
line-height: 40px; /* 86.957% */
}


.home-work-text-div1 p{
font-size: 14px;
line-height: 22px; /* 111.111% */
margin-top: 12px;
}

.home-work-row-div1{
    margin-top: 44px;
    row-gap: 25px;
}

.home-work-card-div1{
padding: 40px 28px;
min-height: 10px;
}

.home-work-card-div1 div{
height: 74px;
display: flex;
align-items: center;
justify-content: center;
}


.home-work-card-div1 p{
margin-top: 12px;
margin-bottom: 4px !important;

}



















 /* {#################### Section No ### 5 ###############} */


.home-leaders-div1{
padding-top: 60px;
padding-bottom: 65px;
}

.home-leaders-text-div1 h1{
font-size: 28px;
line-height: normal;
}

.home-leader-svg1{
    position: absolute;
    right: 6px;
    top: 36px;
    width: 52px;
}

.home-leaders-rowdiv1{
margin-top: 45px;
row-gap: 16px;
display: flex;
flex-direction:row;
flex-wrap: wrap;
}

.home-leaders-rowdiv1 div.main-logo-col-div{
    padding-left: 8px;
    padding-right: 8px;
    width: 50%;
}

.home-leaders-logo-div1{
border-radius: 6.233px;
background: #FFF;
box-shadow: 2.078px 2.078px 6.233px 2.078px rgba(239, 68, 68, 0.10);
min-width: 100%;
height: 132px;
flex-shrink: 0;
/* display: flex;
justify-content: center; */
margin-left: auto;
margin-right: auto;
text-align: center;
}


}

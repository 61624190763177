.contact-banner-div1{
padding: 60px 0px;
padding-bottom: 250px;
}

.contact-banner-text-div1 h1{
color: #042B3F;
text-align: center;
font-family: Poppins;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: 50px; /* 104.167% */
text-transform: uppercase;
}


.contact-banner-text-div1 p{
color: #042B3F;
text-align: center;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 135% */
margin-top: 10px;
}
















.contact-form-div1{

  background: url(../Image/Rectangle\ 4320.svg);
    background-repeat: no-repeat;
    background-size: cover;

}

.contact-form-div1 div.container{
height: 470px;
}

.contact-form-row-div1{
    min-height: 100%;
    transform: translateY(-180px);
    justify-content: center;

}

.contact-form-div2{
     background: #FFF;
box-shadow: 6px -6px 28px 0px rgba(126, 126, 126, 0.10);
padding: 50px;
    min-height: 100%;
}





.contact-form {
  margin-top: -10px;
}

.contact-form .row{
    row-gap: 25px;

}


.form-input-field-div1{
    align-items: center;
    border-bottom: 0.6px solid #DEDEDE;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}



.form-input-field-div1{
    /* margin-top: 30px; */
}

.form-input-field {
  border: none;
 color: #B8B8B8;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 66px; /* 412.5% */
text-transform: capitalize;
  background: transparent;
  /* margin-top: 35px; */
}

.form-control:focus {
  color: #b8b8b8;
  background-color: transparent;
  border-color: #DEDEDE;
  outline: 0;
  box-shadow: none;
}

.form-textarea-field1{
background: #F9F9F9;
color: rgba(4, 43, 63, 0.50);
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 26px; /* 366.667% */
text-transform: capitalize;
padding: 15px;
border: #DEDEDE;
}

.form-textarea-field1:focus{
background: #F9F9F9;

}

.form-btn1 {
  border-radius: 6px;
background: #FFC155;
box-shadow: 0px 0px 0px 0px #FFF inset, 0px 0px 0px 1px #BD801B inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

  padding-top: 18px;
  padding-bottom: 18px;
  color: #042B3F;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 26px; /* 330% */
text-transform: capitalize;
display: flex;
justify-content: center;
align-items: center;
  margin-top: 0px;
  width: 100%;
border: none;
}



.contact-col2-div1 div{
/* background-image: url(../Image/u-s-construction-companies\ 1.png);
background-repeat: no-repeat;
background-size: cover; */
/* margin-left: 15px; */
/* width: 100%; */
height: 100%;
}


.contact-col2-div1 div img{
  max-width: 106% !important;
height: 580px !important;
}

































@media screen and (max-width: 576px) {
  .contact-banner-div1{
padding: 60px 15px;
padding-bottom: 220px;
}

.contact-banner-text-div1 h1{
text-align: left;
font-size: 30px;
line-height: 36px; /* 104.167% */
}

.contact-banner-text-div1 p{
text-align: left;
font-size: 16px;
line-height: 27px; /* 135% */
}

.contact-form-div1 div.container{
height: 360px;
/* margin-bottom: 210px; */
}
.contact-form-row-div1{
    min-height: 100%;
    transform: translateY(-180px);
}
.contact-form-div2{
     padding: 30px;
    min-height: 100%;
}

.contact-form {
  margin-top: -10px;
}

.contact-form .row{
    row-gap: 25px;
}

.form-input-field-div1{
    align-items: center;
    border-bottom: 0.6px solid #DEDEDE;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.form-input-field-div1{
    /* margin-top: 30px; */
}

.form-input-field {
font-size: 14px;
line-height: 20px; /* 412.5% */
}

.form-textarea-field1{
font-size: 14px;
line-height: 20px; /* 366.667% */
padding: 15px;
border: #DEDEDE;
}

.form-btn1 {
  padding-top: 18px;
  padding-bottom: 18px;
font-size: 18px;
line-height: 23px; /* 330% */
}

.contact-col2-div1 div{
background-image: none;
margin-top: 20px;
}

.contact-col2-div1 div img{
  max-width: 100% !important;
height: auto !important;
}

}

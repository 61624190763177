.plans-div1{
    background: #FFFAF2;
    padding:  100px 0px;
}

.plans-text-div1 h1{
text-align: center;
color: #002232;
text-align: center;
font-family: Poppins;
font-size: 52px;
font-style: normal;
font-weight: 700;
line-height: 58px; /* 86.957% */
text-transform: uppercase;
}

.plans-text-div1 p{
    color: #353535;
text-align: center;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 26px; /* 133.333% */
}
















.with-ads-btndiv1{
display: inline-flex;
padding:  4px 4px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 4px;
background: #EDEDED;
margin-top: 22px;
}

.bill-annual-text{
    color: #000;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 142.857% */
display: flex;
padding: 8px 12px;
align-items: flex-start;
gap: 10px;
border-radius: 2px;
background: linear-gradient(90deg, rgba(255, 193, 85, 0.20) -11%, #F5B13D 119%);
}

.with-ads-btn1{
display: flex;
padding: 6px 12px;
min-height: 100%;
justify-content: center;
align-items: center;
gap: 19px;
border-radius: 2px;
background: #FFF;
color: #000;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 100% */
border: none;
transition: 0.3s ease-in-out;
}

.with-ads-btn2{
display: flex;
padding: 6px 12px;
justify-content: center;
align-items: center;
gap: 19px;
background: #EDEDED;
color: #6B7280;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 100% */
border: none;
transition: 0.3s ease-in-out;

}


















.plan-card-rows-div11{
    margin-top: 80px;
}

.price-plan-card-div1{
    border-radius: 6px;
background: #FFF;
box-shadow: 0px 0px 0px 0px #FFF inset, 0px 0px 0px 1px #D1D5DB inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
padding: 30px;
}

.price-plan-card-text-div1{
    padding-bottom: 20px;
border-bottom: 1px solid #E8EAED;
display: flex;
align-items: center;
justify-content: space-between;
}

.price-plan-card-text-div1 h3{
color: #111827;
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 100% */
}

.price-plan-card-text-div1 h4{
color: #000;
text-align: right;
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 100% */
}

.price-plan-comingsoon-text1{
display: flex;
padding: 6px 15px;
justify-content: center;
align-items: center;
border-radius: 40px;
background: #FFBC49;
margin-top: -5px;
}

.price-plan-comingsoon-text1 p{
    color: #111827;
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
    margin-bottom: 0px !important;
}


.price-plan-card-text-div2{
padding-top: 23px;
padding-bottom: 26px;
}

.price-plan-card-text-div2 p:first-child{
color: #252526;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 26px; /* 175% */
display: flex;
align-items: center;
margin-bottom: 0px;
column-gap: 16px;
margin-top: 5px;
}

.price-plan-card-text-div2 p{
color: #6B7280;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 26px;
display: flex;
align-items: center;
margin-bottom: 0px;
column-gap: 16px;
margin-top: 5px;

}


.plans-add-text1{
    color: #8B8B8B;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 18px; /* 200% */
margin-bottom: 0px;
}


.plans-check-box-div1{
    border-radius: 4px;
background:  #FFF;
box-shadow: 0px 0px 0px 0px #FFF inset, 0px 0px 0px 1px #D1D5DB inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
display: flex;
justify-content: space-between;
padding: 16px;
margin-top: 12px;
}

.plans-check-box-div2{
display: flex;
column-gap: 12px;
}

.plans-check-box-div2 h4{
color: #000;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 18px; /* 150% */
margin-bottom: 4px;
}

.plans-check-box-div2 p{
color: #000;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 18px; /* 125% */
margin-bottom: 0px;
}

.plans-check-box-div3 h3{
color: #000;
text-align: right;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 15px; /* 93.75% */
}


.plans-btn1{
    color: #000;
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 142.857% */
border-radius: 6px;
background: #FFC155;
box-shadow: 0px 0px 0px 0px #FFF inset, 0px 0px 0px 1px #BD801B inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
padding-top: 13px;
padding-bottom: 13px;
width: 100%;
margin-top: 20px ;
display: flex;
align-items: center;
justify-content: center;
}



.price-plan-card-text-div22{
padding-top: 20px;
padding-bottom: 26px;
}

.price-plan-card-text-div22 p{
color: #252526;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 26px; /* 175% */
display: flex;
align-items: center;
margin-bottom: 0px;
column-gap: 16px;
margin-top: 5px;
}




















.event-addons-div11{
padding-top: 48px ;
}

.event-addons-div11 .row{
    margin-top: 28px;
    row-gap: 20px;

}

.event-addons-text1{
color: #000;
font-family: Inter;
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: 34px; /* 120% */
text-align: center;
}

.event-addons-card-div1{
border-radius: 4px;
background:#FFF;
box-shadow: 0px 0px 0px 0px #FFF inset, 0px 0px 0px 1px #D1D5DB inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
display: flex;
justify-content: space-between;
padding: 18px;
min-height: 100%;
}

.event-addons-cardtext-div2{
display: block;
}

.event-addons-cardtext-div2 div img{
width: 109% !important;
}

.event-addons-cardtext-div2 div h3{
color: #000;
font-family: Inter;
font-size: 21px;
font-style: normal;
font-weight: 600;
line-height: 21px; /* 150% */
margin-bottom: 10px;

}

.event-addons-cardtext-div2 div p{
color: #000;
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 21px; /* 125% */
margin-bottom: 17px !important;
}

.price-tag11{
background: #FFC155;
padding: 11px 15px;
flex-shrink: 0;
display: flex;
align-items: center;
justify-content: center;
position: absolute;
right: -41px;
top: 20px;
border-radius: 2px;
}

.price-tag11 h4{
    color: #000;
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 18px; /* 93.75% */
margin-bottom: 0px !important;
}

























@media screen and (max-width: 576px) {
  .plans-div1{
    padding:  60px 15px;
}

.plans-text-div1 h1{
font-size: 32px;
line-height: 40px; /* 41.667% */
}

.plans-text-div1 p{
font-size: 16px;
line-height: 24px; /* 133.333% */
}








.with-ads-btndiv1{
margin-top: 22px;
}

.bill-annual-text{
font-size: 14px;
line-height: 20px; /* 142.857% */
padding: 8px 10px;
}

.with-ads-btn1{
padding: 6px 10px;
gap: 13px;
font-size: 17px;
line-height: 20px; /* 100% */
}

.with-ads-btn2{
padding: 6px 10px;
gap: 13px;
font-size: 17px;
line-height: 20px; /* 100% */
}





.plan-card-rows-div11{
    margin-top: 50px;
    row-gap: 20px;
}

.price-plan-card-div1{
    border-radius: 6px;
background: #FFF;
padding: 25px;
}

.price-plan-card-text-div1{
    padding-bottom: 15px;
}

.price-plan-card-text-div1 h3{
font-size: 18px;
line-height: 24px; /* 100% */
}

.price-plan-card-text-div1 h4{
font-size: 18px;
line-height: 24px; /* 100% */
}


.price-plan-card-text-div2{
padding-top: 15px;
padding-bottom: 32px;
}

.price-plan-card-text-div2 p:first-child{
font-size: 15px;
line-height: 24px; /* 175% */
column-gap: 16px;
margin-top: 5px;
}

.price-plan-card-text-div2 p{
font-size: 15px;
line-height: 24px;
column-gap: 16px;
margin-top: 5px;

}



.plans-check-box-div1{
padding: 14px;
margin-top: 12px;
}

.plans-check-box-div2{
display: flex;
column-gap: 10px;
}

.plans-check-box-div2 h4{
font-size: 10px;
line-height: 16px; /* 150% */
margin-bottom: 4px;
}

.plans-check-box-div2 p{
font-size: 10px;
line-height: 16px; /* 125% */
margin-bottom: 0px;
}

.plans-check-box-div3 h3{
font-size: 12px;
margin-top: 2px;
line-height: 15px; /* 93.75% */
}




.price-plan-card-text-div22{
padding-top: 15px;
padding-bottom: 32px;
}

.price-plan-card-text-div22 p{
font-size: 15px;
line-height: 24px; /* 175% */
column-gap: 16px;
margin-top: 5px;
}



.price-plan-comingsoon-text1 p {
    color: #111827;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

















.event-addons-div11{
padding-top: 45px ;
}

.event-addons-div11 .row{
    margin-top: 28px;
    row-gap: 20px;

}

.event-addons-text1{
font-size: 22px;
line-height: 26px; /* 120% */
}

.event-addons-card-div1{
display: flex;
justify-content: space-between;
padding: 18px;
}

.event-addons-cardtext-div2{
display: block;
}

.event-addons-cardtext-div2 div img{
    width: 100% !important;
}


.event-addons-cardtext-div2 div h3{
font-size: 16px;
line-height: 22px; /* 150% */
margin-bottom: 10px;
text-align: center;

}

.event-addons-cardtext-div2 div p{
font-size: 14px;
line-height: 20px; /* 125% */
margin-bottom: 15px !important;
text-align: center;
}






.price-tag11{
padding: 8px 12px;
right: 0px;
top: 20px;
}

.price-tag11 h4{
font-size: 14px;
line-height: 18px; /* 93.75% */
margin-bottom: 0px !important;
}



}

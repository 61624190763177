   @import url(https://themes.googleusercontent.com/fonts/css?kit=frLqg2SrdmaP12_qQR-kkqTvUcFDPb81riXnk0ZB3RBbV0WvE1cEyAoIq5yYZlSc);

   .data-div11{
    padding-top: 80px;
    padding-bottom: 80px;
   }
        .data-div11 ol {
            margin: 0;
            padding: 0
        }

        .data-div11 table td,
        table th {
            padding: 0
        }

      

        .data-div11 .c5 {
            color: #323338;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 12pt;
            font-family: "Poppins";
            font-style: normal
        }


        .data-div11 .c15 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: justify;
            height: 12pt
        }

        .data-div11 .c9 {
            padding-top: 2.2pt;
            padding-bottom: 26px;
            line-height: 36px !important;
            orphans: 2;
            widows: 2;
        }
        
        .data-div11 .c7 {
            font-size: 11pt;
            font-family: "Times New Roman";
            color: #000000;
            font-weight: 400
        }

        .data-div11 .c12 {
            text-decoration: none;
            vertical-align: baseline;
            font-size: 23pt;
            font-style: normal;
        }

        /* .data-div11 .c6 {
            font-size: 11pt;
            font-family: "Poppins";
            color: #323338;
            font-weight: 400
        } */



        /* .data-div11 .c16 {
            font-family: "Poppins";
            color: #323338;
            font-weight: 700 */
        /* } */

        .data-div11 .c8 {
            font-family: "Poppins";
            color: #000000;
            font-weight: 400
        }

        .data-div11 .c13 {
            -webkit-text-decoration-skip: none;
            text-decoration: underline;
            text-decoration-skip-ink: none
        }

        .data-div11 .c14 {
            background-color: #ffffff;
            max-width: 468pt;
            padding: 72pt 72pt 72pt 72pt
        }

        .data-div11 .c3 {
            color: inherit;
            text-decoration: inherit
        }

        .data-div11 .c10 {
            font-size: 11pt
        }

        .data-div11 li {
            color: #000000;
            font-size: 12pt;
            line-height: 22px;
            font-family: "Aptos"
        }




        
 .data-div11 h1{
    color: #042B3F;
font-family: Poppins;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: 58px; /* 104.167% */
text-transform: uppercase;
}

 .data-div11 h2{
    color: #042B3F;
font-family: Poppins;
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: 38px; /* 178.571% */
text-transform: capitalize;
margin-top: 28px;
margin-bottom: 14px;
}

 .data-div11 p{
    color: #545454;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 125% */
margin-bottom: 22px;
}

 /* .data-div11 p span{
    text-decoration: underline;
} */

 .data-div11 .c55{
    font-weight: 700;
 }











@media screen and (max-width: 576px) {

.data-div11 {
  padding-top: 65px;
  padding-bottom: 65px;
}
        
 .data-div11 h1{
font-size: 32px;
line-height: 46px; /* 104.167% */
text-transform: uppercase;
}

 .data-div11 h2{
font-size: 22px;
line-height: 34px; /* 178.571% */
margin-top: 28px;
margin-bottom: 14px;
}

 .data-div11 p{
font-size: 14px;
line-height: 22px; /* 125% */
margin-bottom: 20px;

 }

}
@import url(https://themes.googleusercontent.com/fonts/css?kit=frLqg2SrdmaP12_qQR-kkqTvUcFDPb81riXnk0ZB3RBbV0WvE1cEyAoIq5yYZlSc);

.privacy-div1 {
  padding-top: 80px;
  padding-bottom: 80px;
}
.privacy-div1 .lst-kix_list_1-1 > li {
  counter-increment: lst-ctn-kix_list_1-1;
}

.privacy-div1 .lst-kix_list_3-0 > li:before {
  content: "\0025cf   ";
}

.privacy-div1 ul.lst-kix_list_5-7 {
  list-style-type: none;
}

.privacy-div1 ul.lst-kix_list_5-8 {
  list-style-type: none;
}

.privacy-div1 .lst-kix_list_3-1 > li:before {
  content: "o  ";
}

.privacy-div1 .lst-kix_list_3-2 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 ul.lst-kix_list_5-5 {
  list-style-type: none;
}

.privacy-div1 ul.lst-kix_list_5-6 {
  list-style-type: none;
}

.privacy-div1 ol.lst-kix_list_1-8.start {
  counter-reset: lst-ctn-kix_list_1-8 0;
}

.privacy-div1 .lst-kix_list_3-5 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 ul.lst-kix_list_5-0 {
  list-style-type: none;
}

.privacy-div1 .lst-kix_list_3-4 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 ul.lst-kix_list_5-3 {
  list-style-type: none;
}

.privacy-div1 ol.lst-kix_list_1-5.start {
  counter-reset: lst-ctn-kix_list_1-5 0;
}

.privacy-div1 .lst-kix_list_3-3 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 ul.lst-kix_list_5-4 {
  list-style-type: none;
}

.privacy-div1 ul.lst-kix_list_5-1 {
  list-style-type: none;
}

.privacy-div1 ul.lst-kix_list_5-2 {
  list-style-type: none;
}

.privacy-div1 .lst-kix_list_3-8 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 .lst-kix_list_3-6 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 .lst-kix_list_3-7 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 .lst-kix_list_1-2 > li {
  counter-increment: lst-ctn-kix_list_1-2;
}

.privacy-div1 .lst-kix_list_5-0 > li:before {
  content: "\0025cf   ";
}

.privacy-div1 .lst-kix_list_1-4 > li {
  counter-increment: lst-ctn-kix_list_1-4;
}

.privacy-div1 ol.lst-kix_list_1-6.start {
  counter-reset: lst-ctn-kix_list_1-6 0;
}

.privacy-div1 .lst-kix_list_4-8 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 .lst-kix_list_5-3 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 .lst-kix_list_4-7 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 .lst-kix_list_5-2 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 .lst-kix_list_5-1 > li:before {
  content: "o  ";
}

.privacy-div1 ul.lst-kix_list_4-8 {
  list-style-type: none;
}

.privacy-div1 .lst-kix_list_5-7 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 ul.lst-kix_list_4-6 {
  list-style-type: none;
}

.privacy-div1 .lst-kix_list_5-6 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 .lst-kix_list_5-8 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 ul.lst-kix_list_4-7 {
  list-style-type: none;
}

.privacy-div1 ul.lst-kix_list_4-0 {
  list-style-type: none;
}

.privacy-div1 ul.lst-kix_list_4-1 {
  list-style-type: none;
}

.privacy-div1 .lst-kix_list_5-4 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 ul.lst-kix_list_4-4 {
  list-style-type: none;
}

.privacy-div1 .lst-kix_list_5-5 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 ul.lst-kix_list_4-5 {
  list-style-type: none;
}

.privacy-div1 ul.lst-kix_list_4-2 {
  list-style-type: none;
}

.privacy-div1 ul.lst-kix_list_4-3 {
  list-style-type: none;
}

.privacy-div1 ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0;
}

.privacy-div1 ol.lst-kix_list_1-3 {
  list-style-type: none;
}

.privacy-div1 ol.lst-kix_list_1-4 {
  list-style-type: none;
}

.privacy-div1 .lst-kix_list_2-6 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 .lst-kix_list_2-7 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 ol.lst-kix_list_1-5 {
  list-style-type: none;
}

.privacy-div1 ol.lst-kix_list_1-6 {
  list-style-type: none;
}

.privacy-div1 ol.lst-kix_list_1-0 {
  list-style-type: none;
}

.privacy-div1 .lst-kix_list_2-4 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 .lst-kix_list_2-5 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 .lst-kix_list_2-8 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 ol.lst-kix_list_1-1 {
  list-style-type: none;
}

.privacy-div1 ol.lst-kix_list_1-2 {
  list-style-type: none;
}

.privacy-div1 ul.lst-kix_list_3-7 {
  list-style-type: none;
}

.privacy-div1 ul.lst-kix_list_3-8 {
  list-style-type: none;
}

.privacy-div1 ul.lst-kix_list_3-1 {
  list-style-type: none;
}

.privacy-div1 ul.lst-kix_list_3-2 {
  list-style-type: none;
}

.privacy-div1 ul.lst-kix_list_3-0 {
  list-style-type: none;
}

.privacy-div1 ol.lst-kix_list_1-7 {
  list-style-type: none;
}

.privacy-div1 ul.lst-kix_list_3-5 {
  list-style-type: none;
}

.privacy-div1 .lst-kix_list_1-7 > li {
  counter-increment: lst-ctn-kix_list_1-7;
}

.privacy-div1 ol.lst-kix_list_1-8 {
  list-style-type: none;
}

.privacy-div1 ul.lst-kix_list_3-6 {
  list-style-type: none;
}

.privacy-div1 ul.lst-kix_list_3-3 {
  list-style-type: none;
}

.privacy-div1 ul.lst-kix_list_3-4 {
  list-style-type: none;
}

.privacy-div1 .lst-kix_list_4-0 > li:before {
  content: "\0025cf   ";
}

.privacy-div1 .lst-kix_list_4-1 > li:before {
  content: "o  ";
}

.privacy-div1 ol.lst-kix_list_1-7.start {
  counter-reset: lst-ctn-kix_list_1-7 0;
}

.privacy-div1 .lst-kix_list_4-4 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 .lst-kix_list_1-5 > li {
  counter-increment: lst-ctn-kix_list_1-5;
}

.privacy-div1 .lst-kix_list_4-3 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 .lst-kix_list_4-5 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 .lst-kix_list_4-2 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 .lst-kix_list_4-6 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 .lst-kix_list_1-8 > li {
  counter-increment: lst-ctn-kix_list_1-8;
}

.privacy-div1 ol.lst-kix_list_1-4.start {
  counter-reset: lst-ctn-kix_list_1-4 0;
}

.privacy-div1 ol.lst-kix_list_1-1.start {
  counter-reset: lst-ctn-kix_list_1-1 0;
}

.privacy-div1 ol.lst-kix_list_1-3.start {
  counter-reset: lst-ctn-kix_list_1-3 0;
}

.privacy-div1 ul.lst-kix_list_2-8 {
  list-style-type: none;
}

.privacy-div1 ol.lst-kix_list_1-2.start {
  counter-reset: lst-ctn-kix_list_1-2 0;
}

.privacy-div1 ul.lst-kix_list_2-2 {
  list-style-type: none;
}

.privacy-div1 .lst-kix_list_1-0 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) ". ";
}

.privacy-div1 ul.lst-kix_list_2-3 {
  list-style-type: none;
}

.privacy-div1 ul.lst-kix_list_2-0 {
  list-style-type: none;
}

.privacy-div1 ul.lst-kix_list_2-1 {
  list-style-type: none;
}

.privacy-div1 ul.lst-kix_list_2-6 {
  list-style-type: none;
}

.privacy-div1 .lst-kix_list_1-1 > li:before {
  content: "" counter(lst-ctn-kix_list_1-1, decimal) ". ";
}

.privacy-div1 .lst-kix_list_1-2 > li:before {
  content: "" counter(lst-ctn-kix_list_1-2, decimal) ". ";
}

.privacy-div1 ul.lst-kix_list_2-7 {
  list-style-type: none;
}

.privacy-div1 ul.lst-kix_list_2-4 {
  list-style-type: none;
}

.privacy-div1 ul.lst-kix_list_2-5 {
  list-style-type: none;
}

.privacy-div1 .lst-kix_list_1-3 > li:before {
  content: "" counter(lst-ctn-kix_list_1-3, decimal) ". ";
}

.privacy-div1 .lst-kix_list_1-4 > li:before {
  content: "" counter(lst-ctn-kix_list_1-4, decimal) ". ";
}

.privacy-div1 .lst-kix_list_1-0 > li {
  counter-increment: lst-ctn-kix_list_1-0;
}

.privacy-div1 .lst-kix_list_1-6 > li {
  counter-increment: lst-ctn-kix_list_1-6;
}

.privacy-div1 .lst-kix_list_1-7 > li:before {
  content: "" counter(lst-ctn-kix_list_1-7, decimal) ". ";
}

.privacy-div1 .lst-kix_list_1-3 > li {
  counter-increment: lst-ctn-kix_list_1-3;
}

.privacy-div1 .lst-kix_list_1-5 > li:before {
  content: "" counter(lst-ctn-kix_list_1-5, decimal) ". ";
}

.privacy-div1 .lst-kix_list_1-6 > li:before {
  content: "" counter(lst-ctn-kix_list_1-6, decimal) ". ";
}

.privacy-div1 li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}

.privacy-div1 .lst-kix_list_2-0 > li:before {
  content: "\0025cf   ";
}

.privacy-div1 .lst-kix_list_2-1 > li:before {
  content: "o  ";
}

.privacy-div1 .lst-kix_list_1-8 > li:before {
  content: "" counter(lst-ctn-kix_list_1-8, decimal) ". ";
}

.privacy-div1 .lst-kix_list_2-2 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 .lst-kix_list_2-3 > li:before {
  content: "\0025aa   ";
}

.privacy-div1 ol {
  margin: 0;
  padding: 0;
}

.privacy-div1 table td,
table th {
  padding: 0;
}

.privacy-div1 .c20 {
  color: #042b3f;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 58px; /* 104.167% */
  text-transform: uppercase;
}

.privacy-div1 h1 {
  color: #042b3f;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 58px; /* 104.167% */
  text-transform: uppercase;
}

.privacy-div1 h2 {
  color: #042b3f;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px; /* 178.571% */
  text-transform: capitalize;
  margin-top: 28px;
  margin-bottom: 14px;
}

.privacy-div1 p {
  color: #545454;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 125% */
  margin-bottom: 22px;
}

.privacy-div1 .c0 {
  color: #545454;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
}

.privacy-div1 .c21 {
  color: #4353ff;
  font-weight: 400;
  font-family: "Poppins";
}

.privacy-div1 .c10 {
  background-color: #ffffff;
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}

.privacy-div1 .c16 {
  -webkit-text-decoration-skip: none;
  text-decoration: none;
  text-decoration-skip-ink: none;
}

.privacy-div1 .c13 {
  font-family: "Times New Roman";
  color: #323338;
  font-weight: 400;
}

.privacy-div1 .c18 {
  padding: 0;
  margin: 0;
}

.privacy-div1 .c11 {
  color: inherit;
  text-decoration: inherit;
}

.privacy-div1 .c12 {
  font-style: italic;
}


.privacy-div1 li {
  color: #545454;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 125% */
  margin-bottom: 6px;
  padding-left: 40px;
}






@media screen and (max-width: 576px) {
      
    .privacy-div1 {
  padding-top: 65px;
  padding-bottom: 65px;
}

.privacy-div1 h1{
font-size: 32px;
line-height: 46px; /* 104.167% */
text-transform: uppercase;
}

.privacy-div1 h2{
font-size: 22px;
line-height: 34px; /* 178.571% */
margin-top: 28px;
margin-bottom: 14px;
}

.privacy-div1 p{
font-size: 14px;
line-height: 22px; /* 125% */
margin-bottom: 20px;

 }


.privacy-div1 li {
  font-size: 14px;
  line-height: 22px; /* 125% */
  margin-bottom: 6px;
  padding-left: 30px;
}

.privacy-div1 .c0 {
    font-size: 14px;
    line-height: 20px;
}
}